import { useChatMutation, useUserDataQuery } from "../../api/queries";
import { ReactComponent as Refetch } from "../../assets/icons/refetch.svg";
import { useChatContext } from "../../services/context/chat-context";

export const AskQuestionBox = ({ message, error }) => {
  const { setIsMessageLoading } = useChatContext();

  const { data: userData } = useUserDataQuery();
  const chatMutation = useChatMutation();
  const { setChatValue } = useChatContext();
  const userId = userData?.data?.uid;

  const handleRefetch = (value) => {
    const message = {
      uid: userId,
      messages: [
        {
          role: "user",
          content: value,
        },
      ],
    };
    setIsMessageLoading(true);
    setChatValue((prev) => {
      const updatedMessages = [
        ...prev,
        { ...message.messages[0], isMyMessage: true },
      ];
      return updatedMessages;
    });
    chatMutation.mutate(message, {
      onError: () => {
        setChatValue((prev) => {
          const newArr = prev.slice(0, prev.length - 1);
          return [
            ...newArr,
            { ...message.messages[0], isMyMessage: true, isError: true },
          ];
        });
        setIsMessageLoading(true);
      },
      onSettled: () => setIsMessageLoading(false),
    });
  };

  return (
    <>
      {!error ? (
        <div className="ml-auto max-w-[75%] w-fit flex justify-end p-2 bg-skyBlue rounded-xl rounded-br-none mb-3">
          <span className="font-lato text-sm font-normal text-white leading-[1.313rem]">
            {message.content}
          </span>
        </div>
      ) : (
        <div className="flex justify-end items-center mb-3">
          <div className="mr-2 cursor-pointer">
            <Refetch onClick={() => handleRefetch(message.content)} />
          </div>
          <div className="p-2 bg-danger rounded-xl rounded-br-none w-fit max-w-[75%] leading-[1.313rem]">
            <span className="font-lato text-sm font-normal text-white ">
              {message.content}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

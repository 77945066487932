import { useParams } from 'react-router-dom';
import { useUserDataQuery } from '../../api/queries';

export const ChatWarning = () => {
  const { username } = useParams();
  const { data: userData } = useUserDataQuery(username);

  const user = userData?.data?.full_name;

  return (
    <div className='bg-lightSand text-center flex flex-wrap justify-center'>
      <span className='px-5 py-1 text-xs font-lato font-normal leading-[1.188rem]'>
        This is an AI that chats about {user?.trim()}. At times it may share
        inaccurate information.
      </span>
    </div>
  );
};

import { ReactComponent as Upload } from "../../assets/icons/boxArrowUp.svg";

export const Skeleton = ({ isUserName, isChips }) => {
  return (
    <>
      {isChips ? (
        <div className="p-2 bg-cyanDark rounded-[6.25rem]">
          <div className="w-[3.25rem] h-5 rounded-[6.25rem] bg-smoke" />
        </div>
      ) : null}

      {isUserName ? (
        <section className="w-screen border-b border-cyanLight  py-5 px-2">
          <header className="flex justify-between h-fit">
            <div className="flex gap-3.5 items-center">
              <div className="w-9 h-9 rounded-[6.25rem] dark:bg-cyanDark" />
              <div className="flex flex-col">
                <div className="w-32 h-2.5  bg-gray-200 rounded-full dark:bg-cyanDark mb-2" />
                <div className="flex items-center">
                  <div className="h-2 bg-gray-200 rounded-full dark:bg-cyanDark w-32" />
                </div>
              </div>
            </div>
          </header>
        </section>
      ) : null}
    </>
  );
};

<section className="w-screen border-b border-cyanLight  py-5 px-2">
  <header className="flex justify-between h-fit">
    <div className="flex gap-3.5 items-center">
      <div className="flex flex-col">
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2" />
        <div className="flex items-center">
          <div className="w-48 h-2 bg-gray-200 rounded-full dark:bg-gray-700" />
          {/* <Patch /> */}
        </div>
      </div>
    </div>
    <div>
      <Upload />
    </div>
  </header>
</section>;

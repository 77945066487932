import { useLayoutEffect, useRef, useState } from 'react';
import { useChatContext } from '../../services/context/chat-context';
import { useChatMutation, useUserDataQuery } from '../../api/queries';
import { ReactComponent as SendMessage } from '../../assets/icons/sendMessage.svg';
import { ReactComponent as SendMessageDisabled } from '../../assets/icons/sendMessageDisabled.svg';

export const Input = () => {
  const { setChatValue, setIsMessageLoading, isMessageLoading } =
    useChatContext();
  const chatMutation = useChatMutation();

  const { data: userData, isLoading } = useUserDataQuery();
  const [value, setValue] = useState('');
  const userName = userData?.data?.full_name;
  const userId = userData?.data?.uid;
  // create thread_id like userId from userData
  const textareaRef = useRef(null);
  const MIN_TEXTAREA_HEIGHT = 12;

  useLayoutEffect(() => {
    textareaRef.current.style.height = '1.25rem';
    textareaRef.current.style.maxHeight = '6rem';
    textareaRef.current.style.height = `${Math.max(
      textareaRef.current.scrollHeight,
      MIN_TEXTAREA_HEIGHT,
    )}px`;
  }, [value]);

  const onSubmit = (value) => {
    const message = {
      uid: userId, // add thread_id
      messages: [
        {
          role: 'user',
          content: value,
        },
      ],
    };
    setChatValue((prev) => [
      ...prev,
      {
        ...message.messages[0],
        isMyMessage: true,
        isError: false,
      },
    ]);
    setIsMessageLoading(true);

    chatMutation.mutate(message, {
      onError: () => {
        setChatValue((prev) => {
          const newArr = prev.slice(0, prev.length - 1);
          return [
            ...newArr,
            { ...message.messages[0], isMyMessage: true, isError: true },
          ];
        });
        setIsMessageLoading(true);
      },
      onSettled: () => {
        setIsMessageLoading(false);
      },
    });
    setValue('');
  };

  return (
    <div className='flex items-center bg-cyanDark py-3 pl-6 mx-5 pr-3 rounded-[2.25rem] mb-2 mt-2'>
      <textarea
        className='bg-cyanDark caret-skyBlue font-lato font-regular focus:outline-none placeholder-smokeDark pl-1 pr-1 resize-none text-black text-sm w-full'
        onChange={(e) => {
          setValue(e.target.value);
        }}
        placeholder={
          isLoading ? 'Loading...' : `Ask something about ${userName}`
        }
        ref={textareaRef}
        style={{
          minHeight: MIN_TEXTAREA_HEIGHT,
        }}
        value={value}
      />
      <button
        disabled={!value || isMessageLoading}
        onClick={() => onSubmit(value)}
        type='button'
      >
        {!value || isMessageLoading ? <SendMessageDisabled /> : <SendMessage />}
      </button>
    </div>
  );
};

import { useUserDataQuery } from "../../api/queries";

export const UserBio = () => {
  const { data: userData } = useUserDataQuery();

  const user = userData?.data;

  return (
    <div className="p-2 bg-cyanDark rounded-lg mb-5 text-center">
      <span className="font-lato text-sm font-bold text-smokeLight leading-[1.313rem] ">
        {user?.bio}
      </span>
    </div>
  );
};

import { Helmet } from 'react-helmet';
import errorBig from '../../assets/images/error-big.png';
import errorSmall from '../../assets/images/error-small.png';

export const ErrorPage = () => {
  return (
    <div className='h-full flex justify-center items-center flex-col sm:flex-row'>
      <Helmet>
        <title>404 Error Page</title>
      </Helmet>
      <div className='flex flex-col max-w-xl p-8 sm:pr-0 md:p-8 '>
        <h1 className='text-[2.375rem] sm:text-[3.375rem] text-left mm:text-center sm:text-left font-extrabold font-lato text-darkText leading-[3.325rem] sm:leading-[4.725rem]'>
          Sorry! We ran into an issue.
        </h1>
        <h3 className='text-[1rem] sm:text-[1.125rem] text-left mm:text-center sm:text-left font-lato font-normal pt-6 text-davysGray leading-[1.7rem] sm:leading-[1.913rem]'>
          The page or user that you are looking for could not be found.
        </h3>
        <div className='mt-12 flex justify-between mm:justify-center sm:justify-start'>
          <a href='/home'>
            <button
              className='font-lato h-10 bg-smokeDark text-white w-[8.313rem] text-xs font-bold leading-[1.7rem]'
              type='button'
            >
              Visit Website
            </button>
          </a>
          <a href='https://apps.apple.com/us/app/iceberg-ai/id6470951246'>
            <button
              className='font-lato h-10 bg-skyBlue text-white w-[8.313rem] text-xs font-bold leading-[1.7rem] ml-3'
              type='button'
            >
              Download App
            </button>
          </a>
        </div>
      </div>
      <picture>
        <source media='(max-width: 40rem)' srcSet={errorSmall} />
        <img alt='Error' src={errorBig} />
      </picture>
    </div>
  );
};

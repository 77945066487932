import { AskQuestionBox } from "../AskQuestionBox/ask-question-box";
import { AnswerMessageBox } from "../AnswerMessageBox/answer-message-box";
import { useChatContext } from "../../services/context/chat-context";
import { TextLoading } from "../TextLoading/text-loading";

export const Messages = () => {
  const { isMessageLoading, chatValue } = useChatContext();

  return (
    <>
      {chatValue.map((i, index) => {
        if (i.isMyMessage) {
          return (
            <AskQuestionBox
              error={i.isError}
              index={index}
              key={index}
              message={i}
            />
          );
        }
        return <AnswerMessageBox key={index} message={i} />;
      })}

      {isMessageLoading ? <TextLoading /> : null}
    </>
  );
};

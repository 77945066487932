export const Description = () => {
  return (
    <div className="px-5 pb-6 font-lato text-xs text-center text-smokeDark leading-[1.125rem]">
      <span className="inline-block">
        Control how AI talks about you with MyIceberg.AI.
        <br />
        <a
          className="font-bold underline inline-block cursor-pointer"
          href="https://apps.apple.com/us/app/iceberg-ai/id6470951246"
        >
          Create an account
        </a>
      </span>
    </div>
  );
};

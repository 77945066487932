import axios from "axios";

const options = {
  baseURL: "https://us-central1-myicebergai.cloudfunctions.net/myicebergai_v2",
  headers: {
    "Content-Type": "application/json",
  },
};

export const api = axios.create(options);

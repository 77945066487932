import React, { useEffect, useState } from 'react';

export const BurgerMenu = ({ isOpen }) => {
  const [isVisible, setIsVisible] = useState(isOpen);
  const [menuClass, setMenuClass] = useState(isOpen ? 'block' : '');

  useEffect(() => {
    if (isOpen) {
      setMenuClass('block');
      setIsVisible(true);
    } else {
      setIsVisible(false);
      setTimeout(() => {
        setMenuClass('hidden');
      }, 400);
    }
  }, [isOpen]);

  const menuStyles = {
    transition: 'opacity 0.4s ease-in-out',
    opacity: isVisible ? 1 : 0,
  };

  return (
    <div
      className={`lg:hidden 2xl:visible w-full absolute top-[4.6rem] bg-white px-6 ${menuClass}`}
      style={menuStyles}
    >
      <ul className='flex flex-col items-center text-center '>
        <li className='mb-2 bg-smoke w-full py-2.5'>
          <a className='block w-full' href='mailto:hi@santasailab.com'>
            Contact
          </a>
        </li>
        <li className='mb-2 bg-smoke w-full py-2.5'>
          <a
            className='block w-full'
            href='https://myiceberg.ai/iceberg'
            rel='noopener'
            target='_blank'
          >
            Try Now
          </a>
        </li>
        <li className='bg-smoke w-full py-2.5'>
          <a
            className='block w-full'
            href='https://apps.apple.com/us/app/iceberg-ai/id6470951246'
            rel='noopener'
            target='_blank'
          >
            Download App
          </a>
        </li>
      </ul>
    </div>
  );
};

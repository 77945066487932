import { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Footer } from '../Footer/footer';
import { Header } from '../Header/header';
import { UserBio } from '../UserBio/user-bio';
import { Messages } from '../Messages/messages';
import { useChatContext } from '../../services/context/chat-context';
import { useUserDataQuery } from '../../api/queries';
import { ErrorPage } from '../ErrorPage/error';
import { ChatWarning } from '../ChatWarning/chat-warning';
import { api } from '../../api/api';

export const ChatLayout = () => {
  const [hasLoggedUserId, setHasLoggedUserId] = useState(false);
  const chatContainerRef = useRef(null);
  const { username } = useParams();
  const { isError, data: userData } = useUserDataQuery(username);
  const { chatValue } = useChatContext();
  const userId = userData?.data?.uid;

  const startTimeRef = useRef(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    window.history.replaceState({}, '', '/' + searchParams.get('username'));
  }, []);

  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    chatContainer.scrollTo(0, chatContainer.scrollHeight);
    if (!startTimeRef.current) {
      startTimeRef.current = new Date();
    }
  }, [chatValue]);

  useEffect(() => {
    const handleBlur = () => {
      if (startTimeRef.current && hasLoggedUserId && userId) {
        const endTime = new Date();
        const timeSpent = Math.floor((endTime - startTimeRef.current) / 1000);
        const data = { uid: userId, time_by: timeSpent };
        api.post('/user/record_timing', data);
      }
    };
    const handleFocus = () => {
      startTimeRef.current = new Date();
      setHasLoggedUserId(false);
    };
    window.addEventListener('blur', handleBlur);
    window.addEventListener('focus', handleFocus);
    return () => {
      window.removeEventListener('blur', handleBlur);
      window.removeEventListener('focus', handleFocus);
    };
  }, [userId, hasLoggedUserId]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (startTimeRef.current && userId) {
        const endTime = new Date();
        const timeSpent = Math.floor((endTime - startTimeRef.current) / 1000);
        const data = { uid: userId, time_by: timeSpent };
        api.post('/user/record_timing', data);
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [userId]);

  useEffect(() => {
    if (userId && !hasLoggedUserId) {
      const data = { uid: userId };
      api.post('/user/view_page', data);
      setHasLoggedUserId(true);
    }
  }, [userId, hasLoggedUserId]);

  if (isError) {
    return <ErrorPage />;
  }

  return (
    <div className='flex flex-col overflow-hidden' style={{ height: '100%' }}>
      <Header />
      <ChatWarning />
      <div
        className='px-5 pt-[0.938rem] overflow-y-auto'
        ref={chatContainerRef}
      >
        <UserBio />
        <Messages />
      </div>
      <Footer />
    </div>
  );
};

import React, { createContext, useContext, useState } from "react";

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [chatValue, setChatValue] = useState([]);
  const [isMessageLoading, setIsMessageLoading] = useState(false);

  return (
    <ChatContext.Provider
      value={{ chatValue, setChatValue, isMessageLoading, setIsMessageLoading }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useChatContext must be used within a ChatProvider");
  }
  return context;
};

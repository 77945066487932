import { useState } from 'react';
import { Skeleton } from '../Skeleton/skeleton';
import {
  useChatMutation,
  useSuggestionsQuery,
  useUserDataQuery,
} from '../../api/queries';
import { useChatContext } from '../../services/context/chat-context';
import { ReactComponent as RefetchSuggestions } from '../../assets/icons/refetchSuggestions.svg';

export const Tips = () => {
  const { setChatValue, setIsMessageLoading, isMessageLoading } =
    useChatContext();

  const { data: userData } = useUserDataQuery();
  const { data, isLoading, refetch } = useSuggestionsQuery(userData?.data?.uid);
  const { mutate } = useChatMutation();
  const suggestions = data?.data?.suggestions;
  const userId = userData?.data?.uid;
  // create thread_id like userId from userData

  const [isRefetching, setIsRefetching] = useState(false);

  const handleRefetch = async () => {
    setIsRefetching(true);
    await refetch();
    setIsRefetching(false);
  };
  const onSubmit = (value) => {
    const message = {
      uid: userId, // add thread_id
      messages: [
        {
          role: 'user',
          content: value,
        },
      ],
    };
    setIsMessageLoading(true);

    setChatValue((prev) => [
      ...prev,
      { ...message.messages[0], isMyMessage: true },
    ]);
    mutate(message, {
      onError: () => {
        setChatValue((prev) => {
          const newArr = prev.slice(0, prev.length - 1);
          return [
            ...newArr,
            { ...message.messages[0], isMyMessage: true, isError: true },
          ];
        });
        setIsMessageLoading(true);
      },
      onSettled: () => {
        setIsMessageLoading(false);
      },
    });
  };

  if (isLoading) {
    return (
      <div className='pl-5 overflow-x-scroll overflow-y-hidden no-scrollbar flex gap-2'>
        {Array(10)
          .fill()
          .map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key -- mock data so no another key
            <Skeleton isChips key={index} />
          ))}
      </div>
    );
  }

  return (
    <div className='pl-5 overflow-x-scroll overflow-y-hidden no-scrollbar bg-transparent flex gap-2'>
      {isRefetching
        ? Array(10)
            .fill()
            .map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key -- mock data so no another key
              <Skeleton isChips key={index} />
            ))
        : suggestions?.map((suggestion, index) => (
            <div
              className='bg-skyLight rounded-[6.25rem] py-2 flex items-center'
              key={index}
            >
              <button
                disabled={isMessageLoading}
                onClick={() => {
                  onSubmit(suggestion);
                }}
                tabIndex='0'
                type='button'
              >
                <span className='whitespace-nowrap p-2 text-smokeLight leading-[1.313rem]'>
                  {suggestion}
                </span>
              </button>
            </div>
          ))}
      {!isRefetching && (
        <button
          className='flex bg-skyLight rounded-[6.25rem] p-2  items-center'
          onClick={handleRefetch}
          type='button'
        >
          <span className='whitespace-nowrap  text-smokeLight mr-1.5'>
            More suggestions
          </span>
          <RefetchSuggestions />
        </button>
      )}
    </div>
  );
};

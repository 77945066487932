import { Description } from "../Description/description";
import { Input } from "../Input/input";
import { Tips } from "../Tips/tips";

export const Footer = () => {
  return (
    <div className="mt-auto">
      <Tips />
      <Input />
      <Description />
    </div>
  );
};

import { useCallback, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ChatLayout } from "../components/ChatLayout/chat-layout";
import { ErrorPage } from "../components/ErrorPage/error";
import { HomePage } from "../components/Home/home";
import { ChatProvider } from "../services/context/chat-context";
import trackPathForAnalytics from "../firebase-analytics";

export default function Router() {
  const { pathname, search } = useLocation();

  const analytics = useCallback(() => {
    trackPathForAnalytics({
      path: pathname,
      search,
      title: pathname.split("/")[1],
    });
  }, [pathname, search]);

  useEffect(() => {
    analytics();
  }, [analytics]);

  return (
    <Routes>
      <Route
        element={
          <ChatProvider>
            <ChatLayout />
          </ChatProvider>
        }
        path="/chat"
      />
      <Route element={<HomePage />} path="/home" />
      <Route element={<ErrorPage />} path="*" />
      <Route element={<HomePage />} path="/" />
    </Routes>
  );
}

import { useQuery, useMutation } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { useChatContext } from "../services/context/chat-context.jsx";
import { api } from "./api.js";

export const useUserDataQuery = () => {
  const [searchParams] = useSearchParams();
  const username = searchParams.get("username");

  return useQuery({
    queryKey: ["user", username],
    queryFn: () => api.get(`/user?username=${username}`),
    retry: 0,
    enabled: Boolean(username),
  });
};

export const useSuggestionsQuery = (uid) => {
  return useQuery({
    queryKey: ["suggestions", uid],
    queryFn: () => api.get(`/chat/suggestions?uid=${uid}`),
    retry: 0,
    enabled: uid !== undefined
  });
};

export const useChatMutation = () => {
  const { setChatValue, setIsMessageLoading, chatValue } = useChatContext();

  const messagesData = chatValue.map((item) => { // mapping previous state is not required, we need only last message
    return {
      role: item.role,
      content: item.content,
    };
  });

  return useMutation({
    mutationFn: (content) => {
      const data = {
        uid: content.uid, // add thread_id like thread_id: content.thread_id
        messages: messagesData,
      };
      return api.post("/chat", data);
    },
    onSuccess: (data) => {
      setChatValue((prev) => [...prev, data?.data?.completion]);
      setIsMessageLoading(false);
    },
  });
};

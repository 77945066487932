import React from 'react';
import { Helmet } from 'react-helmet';
import { Skeleton } from '../Skeleton/skeleton';
import { useUserDataQuery } from '../../api/queries';
import { ReactComponent as QuestionIcon } from '../../assets/icons/question.svg';

export const Header = () => {
  const { data: userData, isLoading } = useUserDataQuery();
  const user = userData?.data;
  const profileImageSrc = user?.profile_image_url || null;

  if (isLoading) {
    return (
      <div className='px-2 flex gap-3.5 items-center'>
        <Skeleton isUserName />
      </div>
    );
  }

  return (
    <section className='w-screen  h-[4.375rem] py-[1.125rem]'>
      <Helmet>
        <title>Iceberg AI chat with {user?.full_name}</title>
      </Helmet>
      <header className='flex justify-between px-2 '>
        <div className='flex gap-3.5 items-center'>
          {profileImageSrc ? (
            <img
              alt='avatar'
              className='object-cover w-9 h-9 rounded-[6.25rem]'
              src={profileImageSrc}
            />
          ) : (
            <div className='object-cover w-9 h-9 rounded-[6.25rem] dark:bg-cyanDark' />
          )}
          <div className='flex flex-col'>
            <span className='font-lato font-bold text-sm '>
              {user?.full_name}
            </span>
            <div className='flex items-center'>
              <span className='mr-1 font-lato font-normal text-smokeLight text-xs'>
                myiceberg.ai/{user?.username}
              </span>
            </div>
          </div>
        </div>
        <a className='rounded-[1.6875rem]' href='mailto:hi@santasailab.com'>
          <QuestionIcon />
        </a>
      </header>
    </section>
  );
};

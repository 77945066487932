export const AnswerMessageBox = ({ message }) => {
  return (
    <div className="mr-auto max-w-[75%] w-fit ">
      <div className="p-2 bg-cyanDark mb-3 rounded-xl rounded-bl-none">
        <span className="font-lato text-sm font-normal text-black leading-[1.313rem]">
          {message.content}
        </span>
      </div>
    </div>
  );
};

import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { BurgerMenu } from '../BurgerMenu/burger';
import hero from '../../assets/images/hero.png';
import howToWork from '../../assets/images/how-to-work.png';
import learn from '../../assets/images/learn.png';
import publicSection from '../../assets/images/public.png';
import { ReactComponent as RobotIcon } from '../../assets/icons/robot.svg';
import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg';
import { ReactComponent as SmileIcon } from '../../assets/icons/smile.svg';
import { ReactComponent as LampIcon } from '../../assets/icons/lamp.svg';
import { ReactComponent as LinkIcon } from '../../assets/icons/link.svg';
import { ReactComponent as ShareIcon } from '../../assets/icons/share.svg';
import { ReactComponent as PencilIcon } from '../../assets/icons/pencil.svg';
import { ReactComponent as ShieldIcon } from '../../assets/icons/shield.svg';
import { ReactComponent as LockIcon } from '../../assets/icons/lock.svg';
import { ReactComponent as AppleIcon } from '../../assets/icons/apple.svg';
import { ReactComponent as FacebookLink } from '../../assets/icons/facebook-grey.svg';
import { ReactComponent as TwitterLink } from '../../assets/icons/twitter-grey.svg';
import { ReactComponent as LinkedinLink } from '../../assets/icons/linkedin-grey.svg';
import { ReactComponent as BurgerIcon } from '../../assets/icons/burger.svg';

export const HomePage = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  return (
    <div className='font-lato'>
      <Helmet>
        <title>Iceberg AI Home Page</title>
      </Helmet>
      <div className='flex justify-center'>
        <div className='w-[70.688rem]'>
          <header className='flex items-center justify-between pt-5 pb-5 sticky top-0 bg-white'>
            <div className='flex pl-6'>
              <LogoIcon className='mr-[0.688rem]' />
              <p className='text-2xl font-extrabold'>Iceberg AI</p>
            </div>
            <div>
              <div className='mr-6'>
                <BurgerIcon
                  className='lg:hidden 2xl:visible w-min'
                  onClick={() => setIsNavOpen(!isNavOpen)}
                />
              </div>
            </div>
            <ul className='gap-14 hidden lg:flex items-center text-base font-bold leading-[1.7rem]'>
              <li>
                <a href='mailto:hi@santasailab.com'>Contact</a>
              </li>
              <li>
                <a
                  href='https://myiceberg.ai/iceberg'
                  rel='noopener'
                  target='_blank'
                >
                  <button type='button'>Try Now</button>
                </a>
              </li>
              <li>
                <a
                  href='https://apps.apple.com/us/app/iceberg-ai/id6470951246'
                  rel='noopener'
                  target='_blank'
                >
                  <button
                    className='py-2.5 px-[1.375rem] bg-skyBlue text-white text-base font-bold leading-[1.7rem]'
                    type='button'
                  >
                    Download App
                  </button>
                </a>
              </li>
            </ul>
            <BurgerMenu isOpen={isNavOpen} />
          </header>
          <section className='flex flex-col md:flex-row-reverse items-center pb-14 md:pb-24 justify-between px-6'>
            <img alt='Hero' className='w-[30.5rem]' src={hero} />
            <div className='flex flex-col md:max-w-[24.75rem]'>
              <div className='flex flex-col items-center'>
                <p className='text-[2rem] md:text-5xl text-darkText font-extrabold leading-[2.75rem] md:leading-[4.725rem] inline text-center md:text-left'>
                  Create an AI about you
                  <RobotIcon className='hidden md:inline-block pb-1.5 ml-6' />
                </p>
              </div>
              <p className='text-sm md:text-lg text-davysGray font-normal leading-6 md:leading-[1.913rem] mt-5 text-center md:text-left'>
                Iceberg AI gives you the ability to fully customize and control
                what AI knows about you and how it talks about you.
              </p>
              <a
                className='mt-6 md:mt-[3.5rem]'
                href='https://apps.apple.com/us/app/iceberg-ai/id6470951246'
                rel='noreferrer'
                target='_blank'
              >
                <button
                  className='h-12 md:h-[3.75rem] bg-skyBlue text-white w-full md:w-[12.5rem] text-base font-bold leading-[1.7rem]'
                  type='button'
                >
                  Download App
                </button>
              </a>
            </div>
          </section>

          <section className='flex flex-col md:flex-row items-center pb-14 md:pb-24 justify-between px-6'>
            <img
              alt='How to work'
              className='mb-4 md:mb-0 md:w-[30.5rem]'
              src={howToWork}
            />
            <div className='flex flex-col max-w-[34.25rem]'>
              <p className='text-sm md:text-lg text-skyBlue font-semibold md:font-bold leading-[1.138rem] md:leading-[1.913rem] pb-4 md:pb-1 text-center md:text-left'>
                HOW ICEBERG AI WORKS
              </p>
              <div className='flex items-center flex-col md:flex-row'>
                <p className='text-xl md:text-4xl text-darkText font-bold leading-[2.125rem] md:leading-[3.613rem] inline'>
                  Share your Iceberg AI Link
                  <SmileIcon className='hidden md:inline-block pb-1.5 ml-5' />
                </p>
              </div>
              <p className='text-xs md:text-lg text-davysGray font-normal leading-5 md:leading-[1.913rem] mt-[0.75rem] md:mt-5 text-center md:text-left'>
                Share to Instagram, Snapchat or anywhere else to have your
                friends and followers chat with your AI.
              </p>

              <div className='md:flex mt-5 md:mt-[2.44rem]'>
                <div className='md:mr-6 mb-4 md:mb-0 flex items-center flex-col'>
                  <LinkIcon />
                </div>
                <div>
                  <p className='text-base md:text-lg font-bold text-secondaryDark leading-[1.7rem] md:leading-[1.913rem] mb-[0.31rem] md:mb-[0.38rem] text-center md:text-left'>
                    Link in Bio
                  </p>
                  <p className='text-xs md:text-lg text-davysGray font-normal leading-[1.275rem] md:leading-[1.913rem] text-center md:text-left'>
                    Add your Iceberg AI link to your link in bio to have allow
                    people to chat with your AI at anytime.
                  </p>
                </div>
              </div>

              <div className='md:flex mt-6 md:mt-[3.62rem]'>
                <div className='md:mr-6 mb-4 md:mb-0 flex items-center flex-col'>
                  <ShareIcon />
                </div>
                <div>
                  <p className='text-base md:text-lg font-bold text-secondaryDark leading-[1.7rem] md:leading-[1.913rem] mb-[0.31rem] md:mb-[0.38rem] text-center md:text-left'>
                    Share to your Story
                  </p>
                  <p className='text-xs md:text-lg text-davysGray font-normal leading-[1.275rem] md:leading-[1.913rem] text-center md:text-left'>
                    Post an Instagram or Snapchat Story with your Iceberg AI
                    link to introduce the world to your AI.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className='flex flex-col-reverse md:flex-row items-center pb-14 md:pb-24 justify-between px-6'>
            <div className='flex flex-col max-w-[34.25rem] mt-6 md:mt-0'>
              <p className='text-sm md:text-lg text-skyBlue font-semibold md:font-bold leading-[1.138rem] md:leading-[1.913rem] pb-4 md:pb-1 text-center md:text-left'>
                PUBLIC
              </p>
              <div className='flex flex-col items-center'>
                <p className='text-xl md:text-4xl text-darkText font-bold leading-[2.125rem] md:leading-[3.613rem] text-center md:text-left'>
                  Iceberg AI links should be considered totally public
                </p>
              </div>
              <p className='text-xs md:text-lg text-davysGray font-normal leading-5 md:leading-[1.913rem] mt-[0.75rem] md:mt-5 text-center md:text-left'>
                While you can control who you share your link it could be shared
                further by other people.
              </p>

              <div className='flex mt-[1.44rem] md:mt-9'>
                <div className='mr-[0.61rem] mb-4 md:mb-0'>
                  <LockIcon />
                </div>
                <p className='text-xs md:text-lg font-semibold md:font-bold text-davysGray leading-[1.7rem] md:leading-[1.913rem] mb-0 md:mb-[0.38rem]'>
                  Only put information into Iceberg AI your comfortable being
                  public
                </p>
              </div>

              <div className='flex mt-4'>
                <div className='mr-[0.61rem] mb-4 md:mb-0'>
                  <LockIcon />
                </div>
                <p className='text-xs md:text-lg font-semibold md:font-bold text-davysGray leading-[1.7rem] md:leading-[1.913rem] mb-0 md:mb-[0.38rem]'>
                  Share to your Story
                </p>
              </div>
            </div>
            <img
              alt='Public'
              className='mb-4 md:mb-0 w-[30.5rem]'
              src={publicSection}
            />
          </section>

          <section className='flex flex-col md:flex-row items-center pb-14 md:pb-24 justify-between px-6'>
            <img alt='Learn' className='mb-4 md:mb-0 w-[30.5rem]' src={learn} />
            <div className='flex flex-col max-w-[34.25rem]'>
              <p className='text-sm md:text-lg text-skyBlue font-semibold md:font-bold leading-[1.138rem] md:leading-[1.913rem] pb-4 md:pb-1 text-center md:text-left'>
                LEARN
              </p>
              <div className='flex flex-col items-center md:items-start'>
                <p className='text-xl md:text-4xl text-darkText font-bold leading-[2.125rem] md:leading-[3.613rem] inline'>
                  See what people ask your AI
                  <LampIcon className='hidden md:inline-block pb-1.5 ml-5' />
                </p>
              </div>
              <p className='text-xs md:text-lg text-davysGray font-normal leading-5 md:leading-[1.913rem] mt-[0.75rem] md:mt-5 text-center md:text-left'>
                View all of the message that people sent to your AI and the
                responses that your AI gave.
              </p>

              <div className='md:flex mt-5 md:mt-[2.44rem]'>
                <div className='mr-0 mb-4 md:mb-0 md:mr-6 flex items-center flex-col'>
                  <PencilIcon />
                </div>
                <div>
                  <p className='text-base md:text-lg font-bold text-secondaryDark leading-[1.7rem] md:leading-[1.913rem] mb-[0.31rem] md:mb-[0.38rem] text-center md:text-left'>
                    Improve your AI&apos;s responses
                  </p>
                  <p className='text-xs md:text-lg text-davysGray font-normal leading-[1.275rem] md:leading-[1.913rem] text-center md:text-left'>
                    If you don&apos;t like how your AI responded to a question
                    you can edit the response to improve future response it
                    gives.
                  </p>
                </div>
              </div>

              <div className='md:flex mt-6 md:mt-[3.62rem]'>
                <div className='mr-0 mb-4 md:mb-0 md:mr-6 flex items-center flex-col'>
                  <ShieldIcon />
                </div>
                <div>
                  <p className='text-base md:text-lg font-bold text-secondaryDark leading-[1.7rem] md:leading-[1.913rem] mb-[0.31rem] md:mb-[0.38rem] text-center md:text-left'>
                    Zero tolerance for bully and harassment
                  </p>
                  <p className='text-xs md:text-lg text-davysGray font-normal leading-[1.275rem] md:leading-[1.913rem] text-center md:text-left'>
                    If our AI detects that a message contains bullying or
                    harassment the message will be blocked. These message will
                    not appear in your app.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <section className='h-[17rem] md:h-[28rem] bg-skyLight flex items-center justify-center w-full'>
        <div className='flex flex-col items-center mx-6 w-full'>
          <p className='text-2xl md:text-5xl font-extrabold leading-[2.1rem] md:leading-[4.8rem]'>
            Create your AI
          </p>
          <p className='text-sm md:text-lg font-normal leading-[1.92rem] mb-10 md:mb-[3.12rem] text-center'>
            Avaliable on iOS now and coming to Android soon
          </p>
          <a
            className='md:mt-[3.5rem] w-full md:w-[12.5rem]'
            href='https://apps.apple.com/us/app/iceberg-ai/id6470951246'
            rel='noreferrer'
            target='_blank'
          >
            <button
              className='h-12 md:h-[3.75rem] bg-secondaryDark text-white w-full md:w-[12.5rem] text-base font-bold leading-[1.7rem] flex items-center justify-center '
              type='button'
            >
              <AppleIcon className='mr-[0.7rem]' />
              App Store
            </button>
          </a>
        </div>
      </section>

      <footer className='relative'>
        <div className='flex items-center md:items-start md:justify-between flex-col md:flex-row mx-6 md:mx-[5.78rem] mt-[3.93rem] md:mt-44 pb-24 md:pb-52'>
          <div>
            <div className='flex mb-[1.68rem] md:mb-11'>
              <LogoIcon className='mr-[0.69rem]' />
              <p className='text-2xl font-extrabold'>Iceberg AI</p>
            </div>

            <div className='flex justify-center md:justify-start'>
              <FacebookLink className='mr-6' />
              <TwitterLink className='mr-6' />
              <LinkedinLink />
            </div>
          </div>

          <div className='flex flex-col items-center'>
            <a
              className='mb-2 md:mb-[2.12rem] mt-9 md:mt-0 w-fit'
              href=' https://myiceberg.ai/iceberg'
              rel='noreferrer'
              target='_blank'
            >
              <button
                className='text-lg font-bold leading-[1.92rem] text-center'
                type='button'
              >
                Try Now
              </button>
            </a>
            <a
              className='w-fit md:w-32'
              href=' https://myiceberg.ai/iceberg'
              rel='noreferrer'
              target='_blank'
            >
              <button
                className='text-sm md:text-base font-semibold leading-6 md:leading-7 text-center'
                type='button'
              >
                use the AI about Iceberg AI
              </button>
            </a>
          </div>

          <div className='flex flex-col items-center'>
            <a
              className='mb-2 md:mb-[2.12rem] mt-9 md:mt-0 w-fit'
              href='mailto:hi@santasailab.com'
            >
              <button
                className='text-base md:text-lg font-bold leading-7 md:leading-[1.92rem] md:text-center'
                type='button'
              >
                CONTACT
              </button>
            </a>
            <a className='w-fit' href='mailto:hi@santasailab.com'>
              <button
                className='text-sm md:text-base font-semibold leading-7 text-center block'
                type='button'
              >
                hi@santasailab.com
              </button>
            </a>
          </div>
        </div>

        <p className='text-center text-sm md:text-base font-semibold leading-[1.487rem] md:leading-7 absolute bottom-9 md:bottom-[2.81rem] left-0 right-0'>
          © Copyright 2023 Santa&apos;s AI Lab Inc.
        </p>
      </footer>
    </div>
  );
};
